@import url('https://fonts.googleapis.com/css?family=Open+Sans');

  .button:hover {
    background-color: #f1f1f1;
  }
  .search-button {
      background-color: #01c1d3;
      border: none;
      color: white;
      display: inline-block;
      border-radius: 500px ;
      padding: 7px 34px 9px 34px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
      font-family: "Open Sans";
  }
  

  
    

  .drop-menu-label{
    font-size : 12px;
    color: #333333;
    padding: 5px 10px 5px 10px;
    font-weight: 600;
    display: inline-block;
  
    
  }

  .icon-container {
    display: inline-block;
    background-color: #ebf0fe;
    border-radius: 80%;
    padding: 0px;
    width: 32px;
    text-align: center;
    margin-left: 7px;
    margin-top: 0px;
    position: absolute;
    top: -5px;
  }
  .download-icon {
    margin-left: 2px;
    margin-top: 4px;
    padding-top: 4px;
    font-size: 15px;
    color:"#435282";
  }

.add-new-infection-button {
  padding: 8px 30px 0px 25px;
}

.see-next-contact-button {
  padding: 8px 30px 0px 25px;
}

.view-action-button {
  padding: 10px 25px;
}

.view-map-button {
  padding: 10px 25px;
}

.download-report-button {
  padding: 9px 12px 9px 25px;
}

.search-wrapper {
  display:inline-block;
  border-radius:  500px;
  background-color: white;

}
.search-wrapper  input,
.search-wrapper  button {
  padding: 0 25px;

border:none;
  background-color:transparent;
  border:0;
  outline: none;
}

.search-wrapper  button {
  padding: 10px 25px;

}

.pill {
    background-color: #EAF0FF;
    border: none;
    color: black;
    padding-left: 20px ;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    border-radius: 24px;
    height: fit-content;
    align-items: center;
  }
  
  .button:hover {
    background-color: #f1f1f1;
  }

  
  .drop-menu{
    background-color: #EBF0FE;
    border: none;
    padding-left: 5px ;
    margin-right: 15px ;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    min-width:50px;
    border-left : 1px solid  black;
    color: #333;
    font-size: 12px;
  }

  .icon-container {
    display: inline-block;
    background-color: #ebf0fe;
    border-radius: 80%;
    padding: 0px;
    width: 32px;
    text-align: center;
    margin-left: 7px;
    margin-top: 0px;
    position: absolute;
    top: -5px;
  }
  .download-icon {
    margin-left: 2px;
    margin-top: 4px;
    padding-top: 4px;
    font-size: 15px;
    color:"#435282";
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color:#ECEBF4;
    margin-top: 20px;
    margin-bottom: 20px;
}

.vertical-line {
  height: 100%;
  width: 1px;
  background-color:#ECEBF4;
  margin-left: 20px;
  margin-right: 20px;
}

.horizontal-line-no-margin {
    width: 100%;
    height: 1px;
    background-color:#ECEBF4;
}

.horizontal-line.no-gutter {
  margin-left: -16px;
  margin-right: -16px;
  width: calc(100% + 32px);
}

.vertical-line-no-margin {
  height: 100%;
  width: 1px;
  background-color:#ECEBF4;
}

.add-new-infection-button {
  text-decoration: underline;
  background-color : #57BEC8 ;
  border: none;
  color: white;
  display: inline-block;
  border-radius: 500px ;
  padding: 8px 30px 0px 25px;
  cursor: pointer;
  font-size : 13px;

}

.view-action-button {
  text-decoration: underline;
  background-color : #7D8BB7 ;
  border: none;
  color: white;
  display: inline-block;
  border-radius: 500px ;
  padding: 10px 25px;
  cursor: pointer;
  font-size : 13px;
  font-weight: bold;

}

.view-map-button {
  text-decoration: underline;
  background-color : #2B3552 ;
  border: none;
  color: white;
  display: inline-block;
  border-radius: 500px ;
  padding: 10px 25px;
  cursor: pointer;
  font-size : 13px;
  font-weight: bold;


}

.search-wrapper {
  display:inline-block;
  border-radius:  500px;
  background-color: white;

}
.search-wrapper  input,
.search-wrapper  button {
  padding: 0 25px;

border:none;
  background-color:transparent;
  border:0;
  outline: none;
}

.search-wrapper  button {
  padding: 10px 25px;

}

.table-header {
  background-color: rgb(240 , 243 , 247);
  padding: 20px;
  font-weight: 600;
  color: rgb(121,127,156);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;

}

.table-content {
  color: rgb(50,50,50);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;

}

.table-title{
  font-family: "Open Sans", sans-serif;
  color:#283555;
  margin-bottom: 0px;
  margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
}

.left-card-title {
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0px;
  color: #777F9E;
  font-size: 12px;
  font-weight: 600;
}

.case-title {
  margin-left: 17px;
}

.left-card-content {
  color: #333333;
  font-size: 12px;
  font-weight: 500;
}


.flex-bar{
  display: flex ;
  justify-content: space-between ;
  margin-top: 20px;
}

.flex-bar .MuiButtonBase-root{
  padding-left: 0 !important;
}

.nav-menu-item {
  padding: 19px 69px 24px 35px !important;
  border-bottom: #dddddd 1px solid !important;
  font-size: 15px;
  color: #283555 !important;
  font-weight: bold !important;
  font-family: "Open Sans" !important;
}

.nav-menu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  margin-left: -23px;
  margin-top: 0px;

}

.controls-ui > *{
  margin-left: 12px;
}